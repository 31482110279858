/* eslint-disable react/function-component-definition */
import React from "react";
import { useWhatWeDoHeadingSection } from "../../hooks/useWhatWeDo/useWhatWeDoHeadingSection";
import Jumbotron from "../Jumbotron/Jumbotron";
import MiddleContent from "./components/MiddleContent/MiddleContent";
import TopContent from "./components/TopContent/TopContent";
import IsrVideo from "../IsrVideo/IsrVideo";
import FeaturedPages from "../FeaturedPages/FeaturedPages";
import { useWhatWeDoVideo } from "../../hooks/useWhatWeDo/useWhatWeDoVideo";
import { useWhatWeDoFeaturedPages } from "../../hooks/useWhatWeDo/useWhatWeDoFeaturedPages";
import "./WhatWeDo.scss";

const WhatWeDoComponent = () => {
  const { heading, backgroundImage } = useWhatWeDoHeadingSection();
  const { videoHeading, videoUrl, showSection: showVideo } = useWhatWeDoVideo();
  const { featuredPage1, featuredPage2, showSection } =
    useWhatWeDoFeaturedPages();

  return (
    <div className="what-we-do">
      <Jumbotron media={backgroundImage} title={heading} />
      <TopContent />
      {showVideo && (
        <IsrVideo
          heading={videoHeading}
          videoUrl={videoUrl}
          removeBottomMargin
          removeTopMargin
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <hr size={3} className="divider grey" />
          </div>
          <div className="col-lg-2" />
        </div>
      </div>
      <MiddleContent />
      <FeaturedPages
        featuredPage1={featuredPage1}
        featuredPage2={featuredPage2}
        showSection={showSection}
      />
    </div>
  );
};
export default WhatWeDoComponent;
