import { useStaticQuery, graphql } from "gatsby";

export const useWhatWeDoVideo = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page What We Do" } }) {
        whatWeDo {
          whatWeDoVideo {
            videoHeading
            videoUrl
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.whatWeDo.whatWeDoVideo;
};
