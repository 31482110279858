import { useStaticQuery, graphql } from "gatsby";

export const useWhatWeDoMiddleContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page What We Do" } }) {
        whatWeDo {
          whatWeDoMiddleContent {
            textSection {
              copy
              heading
              showSection
            }
          }
        }
      }
    }
  `);
  return data.wpPage.whatWeDo.whatWeDoMiddleContent;
};
