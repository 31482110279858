import React from "react";
import { useWhatWeDoTopContent } from "../../../../hooks/useWhatWeDo/useWhatWeDoTopContent";
import Spacer from "../../../Spacer/Spacer";

const TopContent = () => {
  const { copy, showSection } = useWhatWeDoTopContent();

  if (showSection)
    return (
      <div className="container">
        <Spacer size={70} mobileSize={20} />
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div
              className="content-wrapper"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
            <Spacer size={10} mobileSize={10} />
            <hr size={3} className="divider grey" />
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    );
  return null;
};

export default TopContent;
