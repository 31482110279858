import { useStaticQuery, graphql } from "gatsby";

export const useWhatWeDoFeaturedPages = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page What We Do" } }) {
        featuredPages {
          showSection
          featuredPage1 {
            ... on WpPage {
              id
              uri
              title
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP)
                    }
                  }
                }
              }
              content
              pageExcerpt {
                pageExcerpt
              }
            }
          }
          featuredPage2 {
            ... on WpPage {
              id
              uri
              title
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP)
                    }
                  }
                }
              }
              content
              pageExcerpt {
                pageExcerpt
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.featuredPages;
};
