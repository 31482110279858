/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import Spacer from "../../../Spacer/Spacer";

const TextSectionItem = ({ copy, heading }) => {
  return (
    <div className="container text-section">
      <Spacer size={20} mobileSize={20} />
      <h2>{heading}</h2>
      <Spacer size={20} mobileSize={10} />
      <div
        className="content-wrapper"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: copy,
        }}
      />
      <Spacer size={10} mobileSize={10} />
      <hr size={3} className="divider grey" />
    </div>
  );
};

TextSectionItem.propTypes = {
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
};

export default TextSectionItem;
