import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import WhatWeDoComponent from "../components/WhatWeDo/WhatWeDo";
import Footer from "../components/Footer/Footer";

const WhatWeDo = () => {
  return (
    <Layout>
      <Header />
      <WhatWeDoComponent />
      <Footer />
    </Layout>
  );
};

export default WhatWeDo;
