import React from "react";
import { useWhatWeDoMiddleContent } from "../../../../hooks/useWhatWeDo/useWhatWeDoMiddleContent";
import TextSectionItem from "../TextSectionItem/TextSectionItem";

const MiddleContent = () => {
  const middleContent = useWhatWeDoMiddleContent();
  return middleContent ? (
    <div className="container">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          {middleContent.map((item) => {
            if (item.textSection.showSection)
              return (
                <TextSectionItem
                  key={`${item.textSection.heading}-key`}
                  heading={item.textSection.heading}
                  copy={item.textSection.copy}
                />
              );
            return null;
          })}
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  ) : null;
};

export default MiddleContent;
